










































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import store from '@/services/store';
import CAbpWindow from '@/modules/budget/execution/form/tabs/pf-abp/AbpWindow.vue';
import CAbpInsideWindow from '@/modules/budget/execution/form/tabs/pf-abp/AbpInsideWindow.vue';
import {Ax} from '@/utils';
import axios from "axios";
import CBudgetAgreementModal from "@/modules/budget-request/components/budget-sum-calc/budget-agreement-modal.vue";
import { EventBus } from '@/modules/budget/eventBus'

interface IFilter {
    abp: number;
    year: number;
    region?: string;
    prg?: number;
    ppr?: number;
}

@Component({
    components: {
        'c-abp-window': CAbpWindow,
        'c-budg-agr-modal': CBudgetAgreementModal,
        'c-abp-inside-window': CAbpInsideWindow
    }
})
export default class CPfAbp extends Vue {
    private moduleCode = '005.001.002'
    private mode = 'expf';
    private statusList: any[] = [];
    private statusListIpf: any[] = [];
    private selectedStatus: any = null;
    private selectedStatusIpf: any = null;
    private isChildsAgreedAbp: boolean = false;

    private editAccess = false; // для редактирование
    private access_level: any | null = null; // уровень доступа (1 просмотр, 2 редактирование)
    @Prop({
        required: true,
        default: 'abp'
    })
    private accessLevel!:any|string;
    private budgetLevel: number[] = [];
    private curYear: number = (new Date()).getFullYear();
    private region = '';
    private reg: any | null = null;
    private obl = null;
    private listReg: any | null = null;
    private filter: null | IFilter = null;
    private nameAbp = '';
    private openSecondWindow = false;
    private isLoadInside = false;
    private checkedABP = false
    private checkedGU = true

    private async created() {
        this.statusList = await this.getStatusList(this.mode)
        this.statusListIpf = await this.getStatusList('exipf')
        if (this.statusList.length) {
            this.selectedStatus = this.statusList.find(el => {
                if (el.code === 0) {
                    return el
                }
            })
        }
        this.curYear = (new Date()).getFullYear();
        await this.getAccess();
        await this.getObl()
        await this.loadRegions();
        // await this.getObl();
        // eslint-disable-next-line
        const that = this;
        this.$watch('usrId', that.loadBudgetLevel);

        await this.loadBudgetLevel();
        EventBus.$on('formationIpfFormChanged', async (formData:any) => {
            await this.formationIpfFormChanged(formData)
        });
    }

    private async mounted() {
        await this.loadOperations();
        this.$watch('currentForm', async () => {
            if (this.currentForm !== null) {
                await this.agreementEvent(false);
                this.isChildsAgreedAbp = true;
                for (const el of this.currentForm.childList) {
                    if (![35, 39].includes(el.status.status)) {
                        this.isChildsAgreedAbp = false;
                        break
                    }
                }
                this.region = this.regions.find((x) => x.code === this.currentForm.region)
            }
        }, { deep: true });
    }

    private formationPfFormChangedEmiter() {
        EventBus.$emit('formationPfFormChanged', this.currentForm)
    }

    //agreement
    private accessLevelNum(): number {
        const nums:any = {
            'ipf': 0, 'abp': 1, 'uf': 2
        }
        if (nums.hasOwnProperty(this.accessLevel)) {
            return nums[this.accessLevel]
        }
        return 0
    }

    getLocalStatus(value:any) {
        const status = value.status.status;
        const curStatus = this.statusList.filter((item) => item.code === status);
        if (!curStatus || !curStatus.length) return status;
        const localName = curStatus[0]["name_" + this.$i18n.locale];
        return localName;
    }

    private async getStatusList(mode: string) {
        try {
            let statusList: any[] = [];
            const response = await axios.get(`/api-py/get-status-list/${mode}`);
            if (response.status === 200) {
                statusList = response.data;
            }
            return statusList;
        } catch (e) {
            console.error("Error fetching status list:", e);
            return [];
        }
    }

    private pfData: any[] = [];
    private get pfList() {
        if (this.selectedStatus && this.selectedStatus.hasOwnProperty('code') && this.selectedStatus.code && !this.checkedABP) {
            return  this.pfData.filter((item: any) => {
                return item.status.status === this.selectedStatus.code
            })
        } else {
            return this.pfData
        }
    }
    private currentForm: any = null;
    private loadingData = false;
    private async loadData() {
        if (this.loadingData) return;
        this.loadingData = true;
        this.pfData = [];
        try {
            const url = `/api/budget-execution-pf-request/${this.accessLevelNum() < 2 || this.checkedABP? 'ABP': 'UF'}?year=${this.curYear}&region=${this.curReg.code}${this.accessLevelNum() < 2 || this.checkedABP? `&abp=${this.curAbp.abp}`: ''}`
            let abpItem:any = {}
            let ufItem:any = {}
            await axios.get(url)
                .then(async (res) => {
                    if (res.status === 200) {
                        if (this.accessLevelNum() < 2 || this.checkedABP) {
                            abpItem = res.data
                            let fullNameExecutor = '';
                            try {
                                const res = await fetch('/api-py/get-realm-user/' + abpItem.user_id);
                                const infoAboutUser = await res.json();
                                if (!infoAboutUser) {
                                    return;
                                }
                                fullNameExecutor = infoAboutUser['lastName'] + ' ' + infoAboutUser['firstName'];
                                // eslint-disable-next-line consistent-return
                            } catch (error) {
                                this.makeToast('danger', 'Ошибка запроса loadExecutor()', error.toString());
                            }
                            abpItem.userName = fullNameExecutor
                            this.pfData.push(abpItem);
                            this.currentForm = abpItem;
                        } else {
                            ufItem = res.data
                            for (const guItem of ufItem.childList) {
                                let fullNameExecutor = '';
                                try {
                                    const res = await fetch('/api-py/get-realm-user/' + guItem.user_id);
                                    const infoAboutUser = await res.json();
                                    if (!infoAboutUser) {
                                        return;
                                    }
                                    fullNameExecutor = infoAboutUser['lastName'] + ' ' + infoAboutUser['firstName'];
                                    // eslint-disable-next-line consistent-return
                                } catch (error) {
                                    this.makeToast('danger', 'Ошибка запроса loadExecutor()', error.toString());
                                }
                                guItem.userName = fullNameExecutor
                                this.pfData.push(guItem);
                            }
                        }
                    }
                })
                .catch(e => {})
        } catch (e) {}
        this.loadingData = false;
    }
    private onChangeForm(form:any) {
        if (form) {
            this.currentForm = form
            this.curAbp = this.abp.find(el => {
                if (el.abp === form.abp) {
                    return el
                }
            })
            if (!this.curAbp) {
                this.curAbp = {
                    abp: form.abp,
                    name_ru: ''
                }
            }
        }
    }
    private operationCode = []
    private async loadOperations() {
        this.operationCode = [];
        if (store.state.user.sub === null) { return; }
        let result = null;
        try {
            result = await fetch(encodeURI(`/api/um/module/link?user=${store.state.user.sub}&modulecode=${this.moduleCode}`))
                .then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки операций пользователя', error.toString());
            return;
        }
        if (result.operations) { this.operationCode = result.operations; }
    }
    // кнопка действия
    private agrBtnLst: any = { back: [], forward: [] };
    private selectArr = [];
    private agrEnabled = false;
    private async agreementEvent(show = true) {
        this.selectArr = [];
        const params = { modeCode: this.mode, operationCode: this.operationCode, agrCode: this.currentForm.status.status };
        if (!show) { this.agrEnabled = false; }
        this.agrBtnLst = { back: [], forward: [] };
        let result: any[] = [];
        try {
            const response = await fetch(`/api-py/get-agreement-step-next-back/${encodeURI(JSON.stringify(params))}`);
            result = await response.json();
        } catch (error) {
            // this.makeToast('danger', 'Ошибка get-agreement-step-next-back', 'Ошибка загрузки шагов согласования!');
            return;
        }
        if (result.length === 0) {
            this.makeToast('warning', 'Согласование', 'Нет доступных шагов согласования!');
            return;
        }
        for (const el of result) {
            if (el.stepType === 1) {
                this.agrBtnLst.back.push(el);
            } else {
                this.agrBtnLst.forward.push(el);
            }
        }
        if (this.agrBtnLst.back.length || this.agrBtnLst.forward.length) {
            this.agrEnabled = true;
        }
        if (show) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs.refAgrModal.showEvent();
        }
    }
    private flk0Agr() {
        // eslint-disable-next-line
        // @ts-ignore
        return this.$refs.abpInsideWindow.flk0agr();
    }
    private async flk2() {
        const url = `/api/budget-execution-pf-request/UF?year=${this.currentForm.year}&region=${this.currentForm.region}`
        let ufItem:any = {}
        await axios.get(url)
            .then(async (res) => {
                if (res.status === 200) {
                    ufItem = res.data
                }
            })
            .catch(e => {})
        if (ufItem) {
            return ![26, 37, 39].includes(ufItem.status.status)
        } else {
            return false
        }
    }
    private async agrClick(data:any) {
        const flk0check = this.flk0Agr()
        const flk2check = await this.flk2()
        if (!flk2check) {
            this.makeToast('danger', 'Согласование', 'Данное действие не доступно')
            return;
        }
        if (data.status === 31 && (!this.isChildsAgreedAbp || !flk0check)) {
            if (!flk0check) {
                this.makeToast('danger', 'Согласование', 'Данное действие не доступно')
                return;
            }
            this.makeToast('warning', 'Согласование', 'Необходимо согласовать ИПФ по всем подведомственным государственным учреждениям')
            return;
        }
        await axios.post(
            '/api/budget-execution-pf-status',
            {
                budgetExecutionPfId: this.currentForm.id,
                comment: data.commentTxt,
                status: data.status
            }
        ).then(res => {
            if (res.status === 200){
                this.formationPfFormChangedEmiter()
                this.toggleOpenWindowBool()
            }
        })
    }

    private async formationIpfFormChanged(formData:any) {
        if (this.openSecondWindow) {
            if (this.currentForm.id !== formData.parent) return;
            const url = `/api/budget-execution-pf-request/ABP?year=${this.curYear}&region=${this.curReg.code}&abp=${this.curAbp.abp}`
            let abpItem:any = {}
            let ufItem:any = {}
            await axios.get(url)
                .then(async (res) => {
                    if (res.status === 200) {
                        if (this.accessLevelNum() < 2 || this.checkedABP) {
                            abpItem = res.data
                            let fullNameExecutor = '';
                            try {
                                const res = await fetch('/api-py/get-realm-user/' + abpItem.user_id);
                                const infoAboutUser = await res.json();
                                if (!infoAboutUser) {
                                    return;
                                }
                                fullNameExecutor = infoAboutUser['lastName'] + ' ' + infoAboutUser['firstName'];
                                // eslint-disable-next-line consistent-return
                            } catch (error) {
                                this.makeToast('danger', 'Ошибка запроса loadExecutor()', error.toString());
                            }
                            abpItem.userName = fullNameExecutor
                            this.pfData.push(abpItem);
                            this.currentForm = abpItem;
                        } else {
                            ufItem = res.data
                            for (const guItem of ufItem.childList) {
                                let fullNameExecutor = '';
                                try {
                                    const res = await fetch('/api-py/get-realm-user/' + guItem.user_id);
                                    const infoAboutUser = await res.json();
                                    if (!infoAboutUser) {
                                        return;
                                    }
                                    fullNameExecutor = infoAboutUser['lastName'] + ' ' + infoAboutUser['firstName'];
                                    // eslint-disable-next-line consistent-return
                                } catch (error) {
                                    this.makeToast('danger', 'Ошибка запроса loadExecutor()', error.toString());
                                }
                                guItem.userName = fullNameExecutor
                                this.pfData.push(guItem);
                            }
                        }
                    }
                })
                .catch(e => {})
        } else {
            await this.chgData()
        }
    }

    private async getAccess() {
        try {
            const result = await fetch(`/api-py/budget-execution-get-access-info/005.001.002/${this.usrId}`);
            if (result.status === 200) {
                const res = await result.json();
                if (res) {
                    this.access_level = res['access_level']
                    if (this.access_level === 2 || this.access_level === 3) {
                        this.editAccess = true;
                    }
                }
            } else {
                this.makeToast('danger', 'get-access', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-access', error.toString());
        }
    }

    private get usrId() {
        if (store.state.user.sub === undefined) {
            return null;
        }
        return store.state.user.sub;
    }

    // ------------------ администратор программ abp------------------
    private abpBase: any[] = [];
    private curAbp: any | null = null;
    private abpDict: any = {};

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }

        if (this.curAbp !== null) {
            this.curAbp = this.setNameLang(this.curAbp, 'abp');
        }
        return res;
    }

    private async chgAbp() {
        this.progBase = [];
        this.curProg = null;
        this.subProgBase = [];
        this.curSubProg = null;
        this.locationBase = []
        this.curLocation = null;
        await this.loadProg();
        await this.loadSubProg();
        await this.loadCurSpf();
    }

    // ------------------------ regions --------------------------
    private regBase: any | null = [];
    private curReg: any | null = null;

    private get regions(): any[] {
        const res: any[] = [];
        if (this.regBase) {
            for (const el of this.regBase) {
                res.push(this.setNameLang(el, 'code'));
            }
        }
        if (this.curReg !== null) {
            this.curReg = this.setNameLang(this.curReg, 'code');
        }
        return res;
    }


    // ------------------------ программа --------------------------
    private progBase: any[] = [];
    private curProg: any | null = null;

    private get prog(): any[] {
        const res: any[] = [];
        for (const el of this.progBase) {
            res.push(this.setNameLang(el, 'prg'));
        }
        if (this.curProg !== null) {
            this.curProg = this.setNameLang(this.curProg, 'prg');
        }
        return res;
    }

    private async chgProg() {
        this.subProgBase = [];
        this.curSubProg = null;
        await this.loadSubProg();
        await this.loadCurSpf();
    }

    // ----------------------------подпрограмма------------------------
    private subProgBase: any[] = [];
    private curSubProg: any | null = null;

    private get subProg(): any[] {
        const res: any[] = [];
        for (const el of this.subProgBase) {
            res.push(this.setNameLang(el, 'ppr'));
        }
        if (this.curSubProg !== null) {
            this.curSubProg = this.setNameLang(this.curSubProg, 'ppr');
        }
        return res;
    }

    private async chgSubProg() {
        await this.loadCurSpf();
    }

    // -------------------- load
    private async loadBudgetLevel() {
        this.budgetLevel = [];
        if (this.usrId === null) {
            return;
        }
        let result = [];
        try {
            result = await fetch(encodeURI('/api-py/get-user-budget-level/' + this.usrId)).then(response => response.json());
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки уровня бюджета', error.toString());
            return;
        }
        for (const el of result) {
            // eslint-disable-next-line @typescript-eslint/camelcase
            const indx = el.budget_level.lastIndexOf('_');
            if (indx >= 0) {
                this.budgetLevel.push(parseInt(el.budget_level.substr(indx + 1)));
            }
        }
        if (this.budgetLevel.length === 0) {
            this.makeToast('warning', 'Внимание!', 'Нет доступных уровней бюджета у пользователя!');
        }
        await this.loadAbp();
    }

    private curLocation:any = null;
    private locationBase:any[] = [];
    private async loadLocations() {
        await axios.get(`/api-py/get-gu-for-abp-261/${this.curYear}/${this.obl}`)
            .then(resp => {
                if (resp.status === 200) {
                    this.locationBase = resp.data
                }
                this.locationBase = [{
                    code: 0,
                    name_ru: 'Все',
                    name_kk: 'Барлығы'
                }, ...this.locationBase]
                this.curLocation = this.setNameLang(this.locationBase[0], 'code');
            }).catch(error => {

            })
    }

    private get locations(): any[] {
        const res: any[] = [];
        if (this.locationBase) {
            for (const el of this.locationBase) {
                res.push(this.setNameLang(el, 'code'));
            }
        }
        return res;
    }

    private async loadRegions() {
        let response: any = [];
        try {
            response = await fetch(`/api-py/user-region-msu-by-uid/${this.usrId}`).then(response => response.json());
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки Регионов', error.toString());
        }
        this.regBase = response;

        if (this.regBase.length > 0) {
            this.curReg = this.regBase[0];
        } else {
            this.curReg = null;
        }
    }

    private async loadAbp() {
        if (!this.budgetLevel || this.budgetLevel.length < 1) {
            return;
        }
        const url = `/api-py/get-abp-by-user-id-region/${this.usrId}/${this.curReg.code}/${this.curYear}`
        let response: any = [];
        try {
            response = await fetch(url);
            if (response.status === 200) {
                response = await response.json();
                response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
            } else {
                this.makeToast('danger', 'Ошибка загрузки АБП', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            this.makeToast('danger', 'Ошибка загрузки АБП', error.toString());
        }

        this.abpBase = this.setIdArr(response, 'abp');

        if (this.abpBase.length > 0) {
            this.curAbp = this.abpBase[0];
            this.abpDict = {}
            for (const abp of this.abp) {
                this.abpDict[abp.abp] = abp
            }
        } else {
            this.curAbp = null;
        }
        if (!this.openSecondWindow) {
            await this.chgData();
        }
    }

    private async getObl() {
        let region = this.region;
        try {
            const result = await fetch('/api-py/get-budget-obl/' + store.state._instanceCode);
            if (result.status === 200) {
                const json = await result.json();
                region = json.region;
                this.obl = json.obl;
            } else {
                this.makeToast('danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-budget-obl', (error as Error).toString());
        }
    }


    private async loadProg() {
        let response: any = [];
        this.progBase = [];

        try {
            response = await fetch(`/api-py/have-prg-in-execution-ipf-by-abp/${this.curReg.code}/${this.curAbp && this.curAbp.hasOwnProperty('abp') && this.curAbp.abp? this.curAbp.abp: 0}`);
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки программ', error.toString());
        }
        if (response && response.length > 0) {
            this.progBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'prg');
        }

        if (this.progBase.length > 0) {
            this.curProg = this.progBase[0];
            await this.chgProg();
        } else {
            this.curProg = null;
        }
    }

    private async loadSubProg() {
        let response: any = [];
        this.subProgBase = [];

        try {
            const prg = `${this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg? this.curProg.prg: 0}`
            response = await fetch(`/api-py/have-ppr-in-execution-ipf-by-abp-and-prg/${this.curReg.code}/${this.curAbp.abp}/${prg}`);
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки подпрограмм', error.toString());
            response = [];
        }
        if (response && response.length > 0) {
            this.subProgBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', ppr: null}, ...response], 'ppr');
        }

        if (this.subProgBase.length > 0) {
            this.curSubProg = this.subProgBase[0];
            await this.chgSubProg();
        } else {
            this.curSubProg = null;
        }
    }

    private spfBase: any[] = [];
    private curSpf: any | null = null;


    private get spf(): any[] {
        const res: any[] = [];
        for (const el of this.spfBase) {
            res.push(this.setNameLang(el, 'spf'));
        }

        if (this.curSpf !== null) {
            this.curSpf = this.setNameLang(this.curSpf, 'spf');
        }
        return res;
    }

    private async loadCurSpf() {
        this.spfBase = [];
        let response: any = [];

        try {
            const abp = `${this.curAbp && this.curAbp.hasOwnProperty('abp') && this.curAbp.abp? this.curAbp.abp: 0}`
            const prg = `${this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg? this.curProg.prg: 0}`
            const ppr = `${this.curSubProg && this.curSubProg.hasOwnProperty('ppr') && this.curSubProg.ppr? this.curSubProg.ppr: 0}`
            response = await fetch(`/api-py/have-spf-in-execution-ipf-by-abp-and-prg-ppr/${this.curReg.code}/${abp}/${prg}/${ppr}`);
            response = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса loadCurSpf()', error.toString());
        }

        if (response && response.length > 0) {
            this.spfBase = this.setIdArr([{name_ru: 'Все', name_kk: 'Барлығы', spf: null}, ...response], 'spf');
        }

        if (this.spfBase.length > 0) {
            this.curSpf = this.spfBase[0];
        } else {
            this.curSpf = null;
        }
    }

    private chgLoad(isLoad: boolean) {
        this.isLoadInside = isLoad;
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            if (obj[codeName]) {
                txt = obj[codeName] + ' - ' + txt;
            }
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private setIdArr(arr: any[], codeName: string) {
        const result: any[] = [];
        if (arr && arr.length > 0) {
            for (const el of arr) {
                if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({idArr: [el.id]}, el));
                }
            }
        }
        return result;
    }

    private async chgData() {
        this.filter = {
            year: this.curYear,
            abp: this.curAbp.abp,
        };
        if (this.curReg && this.curReg.hasOwnProperty('code') && this.curReg.code) {
            this.$set(this.filter, 'region', this.curReg.code);
        }
        this.nameAbp = this.curAbp.name_ru;
        if (this.openSecondWindow) {
            if (this.curProg && this.curProg.hasOwnProperty('prg') && this.curProg.prg) {
                this.$set(this.filter, 'prg', this.curProg.prg);
            }
            if (this.curSubProg && this.curSubProg.hasOwnProperty('ppr') && this.curSubProg.ppr) {
                this.$set(this.filter, 'ppr', this.curSubProg.ppr);
            }
            if (this.curSpf && this.curSpf.hasOwnProperty('spf') && this.curSpf.spf) {
                this.$set(this.filter, 'spf', this.curSpf.spf);
            }
        } else {
            await this.loadData()
        }
    }

    private async toggleOpenWindowBool(row: any = null) {
        this.currentForm = row
        this.openSecondWindow = !this.openSecondWindow;
        if (this.openSecondWindow) {
            if (this.accessLevelNum() === 2) {
                this.curAbp = null;
                if (this.abpBase.length) {
                    this.curAbp = this.abp.find(el => {
                        if (el.abp === row.abp) {
                            return el
                        }
                    })
                    if (!this.curAbp) {
                        this.curAbp = {
                            abp: row.abp,
                            name_ru: ''
                        }
                    }
                }
            }
            if (this.statusListIpf.length) {
                if (this.currentForm && this.currentForm.hasOwnProperty('status') && this.currentForm.status.status) {
                    if (this.currentForm.status.status === 39) {
                        this.selectedStatusIpf = this.statusListIpf.find(el => { if (el.code === 39) return el })
                    } else {
                        this.selectedStatusIpf = this.statusListIpf.find(el => { if (el.code === 35) return el })
                    }
                }
            }
            if (this.curAbp && this.curAbp.hasOwnProperty('abp') && this.curAbp.abp === 261) this.loadLocations()
            await this.loadProg();
        } else {
            if (this.accessLevelNum() === 2 && !this.checkedABP) {
                this.curAbp = this.setNameLang(this.abpBase[0], 'abp')
            }
        }
        setTimeout(async () => {
            await this.chgData();
        }, 100);
    }

    private showSaveBtn = false;

    private async onDelete(row:any) {
        if (row && row.hasOwnProperty('status') && row.status) {
            if (row.status.status !== 1) {
                this.makeToast('warning', 'Удаление', 'Данное действие не доступно для данного ПФ')
                return;
            }
            await axios.delete(`/api/budget-execution-pf-request?requestId=${row.id}`).then(resp => {
                if (resp.status === 200) {
                    this.makeToast('info', 'Удаление', 'ПФ успешно удален!')
                    const idx = this.pfData.findIndex(item => item.id === row.id);
                    if (idx !== -1) {
                        this.pfData.splice(idx, 1);
                    }
                }
            })
        }
    }

    private async save() {
        this.showSaveBtn = true;
        // eslint-disable-next-line
        // @ts-ignore
        await this.$refs.ipfInsideWindow.prepareForSave();
        await this.prepareIpfForSave();
        this.showSaveBtn = false;
    }

    private async prepareIpfForSave() {
        const ipfValues = [];
        // let error = false;
        const item = Object.assign({}, this.filter);
        this.$set(item, 'defective', 'Проверено');
        ipfValues.push(item);

        if (ipfValues.length > 0) {
            await this.saveIpf(ipfValues);
        } else {
            this.makeToast('warning', 'Сообщение', 'Данные не сохранены (ИПФ)');
        }
    }

    private async saveIpf(values: any, error = false) {
        try {
            const response = await fetch('/api-py/save-budget-execution-ipf-gu/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(values)
            });
            const result = await response.json();
            if ((response.status === 200) && (result.result === 'success')) {
                // this.makeToast('success', 'Сообщение', 'Данные сохранены (ИПФ)');
            } else {
                throw 'Ошибка сохранения данных. (ИПФ)';
            }
        } catch (e) {
            this.makeToast('danger', 'Предупреждение', e.toString());
        }
    } // сохранение данных

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.drop;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }


    private downloadRepObligations() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/9/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Проект плана финансирования администратора бюджетных программ по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepObligations()', error.toString());
            }
        );
    }

    private downloadRepIndObligations() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/10/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'План финансирования администратора бюджетных программ по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndObligations()', error.toString());
            }
        );
    }

    private downloadRepIndAbpPayments20() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/20/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'План финансирования администратора бюджетных программ по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndAbpPayments20()', error.toString());
            }
        );
    }

    private downloadRepIndAbpObligations21() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/21/obligation',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'План финансирования администратора бюджетных программ по обязательствам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndAbpObligations21()', error.toString());
            }
        );
    }

    private downloadRepPayments() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/7/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Проект плана финансирования администратора бюджетных программ по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepPayments()', error.toString());
            }
        );
    }

    private downloadRepIndPayments() {
        Ax(
            {
                url: '/api-py/get-budget-execution-abp-report/' + JSON.stringify(this.filter) + '/8/payment',
                method: 'POST',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'План финансирования администратора бюджетных программ по платежам.xls');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepIndPayments()', error.toString());
            }
        );
    }

    private downloadIISKReport(app: number) {
        let url = `/api/budget-execution-base/xls?planType=EXPENSE&type=${app % 2? 'PAYMENT': 'OBLIGATION'}&addAllAgreed=false&addIpf=true&addSpp=false&year=${this.curYear}&region=${this.curReg.code}&code=IPF_PP`
        if ((this.curAbp && this.curAbp.hasOwnProperty('abp') && this.curAbp.abp) || this.currentForm) {
            if (!this.curAbp && this.curAbp.hasOwnProperty('abp') && this.curAbp.abp) {
                url += `&abp=${this.currentForm.abp}`
            } else {
                url += `&abp=${this.curAbp.abp}`
            }
        }
        Ax(
            {
                url: url,
                method: 'GET',
                responseType: 'blob'
            },
            (data: any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ИПФ(${app % 2? 'Платежи': 'Обязательства'})_${this.curAbp.abp}_${this.curYear}.xls`);
                document.body.appendChild(link);
                link.click();
            },
            (error) => {
                this.makeToast('danger', 'Ошибка запроса downloadRepPayments()', error.toString());
            }
        );
    }

}
