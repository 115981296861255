










































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import store from '@/services/store';
import { makeToast } from '@/modules/budget-request/components/js/some-functions';


@Component({
    components: {}
})
export default class CIncomeAddItemModal extends Vue {
    @Prop({
        required: true,
        default: false
    })
    private show!: boolean;

    @Prop({
        required: false,
        default: []
    })
    private dataTable!: any[];

    @Prop({
        required: false,
        default: null
    })
    private filterKat!: any;

    @Prop({
        required: false,
        default: null
    })
    private filterClss!: any;

    @Prop({
        required: false,
        default: null
    })
    private filterPcl!: any;

    @Prop({
        required: false,
        default: null
    })
    private filterSpf!: any;

    @Prop({
        required: false,
        default: new Date().getFullYear()
    })
    private filterYear!: any;

    @Prop({
        required: false,
        default: ''
    })
    private filterRegion!: any;

    @Prop({
        required: false,
        default: false
    })
    private isEmptyTotal!: any;

    private showModal = false;

    private get isReadyToAdd(): boolean {
        if (!this.curKat || !this.curCls || !this.curPcl || !this.curSpf.length) {
            return false;
        }
        return true;
    }

    // ------------- kat -------------------
    private katBase: any[] = [];
    private curKat: any | null = null;

    private async loadKat() {
        this.katBase = this.isEmptyTotal? []: await this.loadDictEbkDohChild('kat');
        this.katBase = this.katBase.filter(item => item.kat !== 8)
        if  (this.katBase.length) {
            this.curKat = this.setNameLang(this.katBase[0], 'kat');
            const filEl = this.filterKat
            if (filEl !== null && filEl.hasOwnProperty('code') && filEl.code !== null) {
                this.curKat = this.setNameLang(this.katBase.find(obj => obj.kat === filEl.code))
                this.katBase = [this.curKat]
            }
        } else {
            this.katBase = await this.loadDictEbkDoh(0);
            this.katBase = this.katBase.filter(item => item.kat !== 8)
            if  (this.katBase.length) {
                this.curKat = this.setNameLang(this.katBase[0], 'kat');
                const filEl = this.filterKat
                if (filEl !== null && filEl.hasOwnProperty('code') && filEl.code !== null) {
                    this.curKat = this.setNameLang(this.katBase.find(obj => obj.kat === filEl.code))
                    this.katBase = [this.curKat]
                }
            }
        }
        this.chgKat();
    }
    private get katLst() {
        const result: any[] = [];
        for (const el of this.katBase) {
            result.push(this.setNameLang(el, 'kat'));
        }
        return result;
    }

    private chgKat() {
        this.curCls = null;
        this.curPcl = null;
        this.curSpf = [];
        this.clsBase = [];
        this.pclBase = [];
        this.spfBase = [];
        if (this.curKat) {
            this.loadCls(this.curKat.id);
        } else {
            this.loadCls(null);
        }
    }

    // ----------- cls -----------------
    private isLoadingCls = false;
    private clsBase: any[] = [];
    private curCls: any | null  = null;

    private async loadCls(id: number | null) {
        this.curCls = null;
        this.isLoadingCls = true;
        this.clsBase = this.isEmptyTotal? []: await this.loadDictEbkDohChild('cls');
        if  (this.clsBase.length) {
            this.curCls = this.setNameLang(this.clsBase[0], 'clss');
            const filEl = this.filterClss
            if (filEl !== null && filEl.hasOwnProperty('code') && filEl.code !== null) {
                this.curCls = this.setNameLang(this.clsBase.find(obj => obj.clss === filEl.code), 'clss')
                this.clsBase = [this.curCls]
            }
        } else {
            this.clsBase = await this.loadDictEbkDoh(id);
            if  (this.clsBase.length) {
                this.curCls = this.setNameLang(this.clsBase[0], 'clss');
                const filEl = this.filterClss
                if (filEl !== null && filEl.hasOwnProperty('code') && filEl.code !== null) {
                    this.curCls = this.setNameLang(this.clsBase.find(obj => obj.clss === filEl.code), 'clss')
                    this.clsBase = [this.curCls]
                }
            } else {
                this.curCls = null;
            }
        }
        this.isLoadingCls = false;
        this.chgCls();
    }

    private get clsLst() {
        const result: any[] = [];
        for (const el of this.clsBase) {
            result.push(this.setNameLang(el, 'clss'));
        }

        if (this.curCls !== null) {
            this.curCls = this.setNameLang(this.curCls, 'clss');
        }
        return result;
    }

    private chgCls() {
        if (this.curCls) {
            this.loadPcl(this.curCls.id);
        } else {
            this.loadPcl(null);
        }
    }

    // ------------ pcl ------------------
    private isLoadingPcl = false;
    private pclBase: any[] = [];
    private curPcl: any | null  = null;

    private async loadPcl(id: number | null) {
        this.curPcl = null;
        this.isLoadingPcl = true;
        this.pclBase = this.isEmptyTotal? []: await this.loadDictEbkDohChild('pcl');
        if  (this.pclBase.length) {
            this.curPcl = this.setNameLang(this.pclBase[0], 'pcl');
            const filEl = this.filterPcl
            if (filEl !== null && filEl.hasOwnProperty('code') && filEl.code !== null) {
                this.curPcl = this.setNameLang(this.pclBase.find(obj => obj.pcl === filEl.code), 'pcl')
                this.pclBase = [this.curPcl]
            }
        } else {
            this.pclBase = await this.loadDictEbkDoh(id);
            if  (this.pclBase.length) {
                this.curPcl = this.setNameLang(this.pclBase[0], 'pcl');
                const filEl = this.filterPcl
                if (filEl !== null && filEl.hasOwnProperty('code') && filEl.code !== null) {
                    this.curPcl = this.setNameLang(this.pclBase.find(obj => obj.pcl === filEl.code), 'pcl')
                    this.pclBase = [this.curPcl]
                }
            } else {
                this.curPcl = null;
            }
        }
        this.isLoadingPcl = false;
        this.chgPcl();
    }

    private get pclLst() {
        const result: any[] = [];
        for (const el of this.pclBase) {
            result.push(this.setNameLang(el, 'pcl'));
        }

        if (this.curPcl !== null) {
            this.curPcl = this.setNameLang(this.curPcl, 'pcl');
        }
        return result;
    }

    private chgPcl() {
        if (this.curPcl) {
            this.loadSpf(this.curPcl.id);
        } else {
            this.loadSpf(null);
        }
    }

    // ------------ spf -----------------
    private isLoadingSpf = false;
    private spfBase: any[] = [];
    private curSpf: any[]  = [];

    private async loadSpf(id: number | null) {
        this.curSpf = [];
        this.isLoadingSpf = true;
        this.spfBase = await this.loadDictEbkDoh(id);
        if  (this.spfBase.length) {
            this.curSpf = [this.setNameLang(this.spfBase[0], 'spf')]
            const filEl = this.filterSpf
            if (filEl !== null && filEl.hasOwnProperty('code') && filEl.code !== null) {
                this.curSpf = [this.setNameLang(this.spfBase.find(obj => obj.spf === filEl.code), 'spf')]
                this.spfBase = [this.curSpf]
            }
        } else {
            this.curSpf = [];
        }
        this.isLoadingSpf = false;
    }

    private get spfLst() {
        const result: any[] = [];
        for (const el of this.spfBase) {
            result.push(this.setNameLang(el, 'spf'));
        }
        return result;
    }

    // -----------------------------------

    private mounted() {
        this.$watch('show', () => {
            if (this.show) {
                this.showModal = this.show;
            }
            this.$emit('shown');
        });
        this.$watch('filterKat', () => {
                try {
                    this.loadKat()
                } catch (e) {}
        });
        this.$watch('filterClss', () => {
            try {
                if (this.filterClss !== null && this.filterClss.hasOwnProperty('code') && this.filterClss.code !== null) this.loadCls(this.curKat.id)
            } catch (e) {}

        });
        this.$watch('filterPcl', () => {
            try {
                if (this.filterPcl !== null && this.filterPcl.hasOwnProperty('code') && this.filterPcl.code !== null) this.loadPcl(this.curCls.id)
            } catch (e) {}

        });
        this.$watch('filterSpf', () => {
            try {
                if (this.filterSpf !== null && this.filterSpf.hasOwnProperty('code') && this.filterSpf.code !== null) this.loadSpf(this.curPcl.id)
            } catch (e) {}
        });
        this.loadKat();
    }

    private async loadDictEbkDohChild(filter: any) {
        let path = `/api-py/budget-execution-income/filter/${filter}/${this.filterYear}/${this.filterRegion}`
        if (this.curKat != null && ['cls', 'pcl'].includes(filter)) {
            path += `/${this.curKat.kat}`
        }
        if (this.curCls != null && filter === 'pcl') {
            path += `/${this.curCls.cls}`
        }
        if (path.includes('undefined')) return [];


        let result: any = [];
        try {
            const response: any =  await fetch(path)
            result = await response.json();
            return result;
        } catch (error) {
            makeToast(this, 'danger', `Ошибка loadDictEbkDohChild (${filter})`, (error as Error).toString());
            return [];
        }
    }

    private async loadDictEbkDoh(id: number | null) {
        if (id === null) {
            return [];
        }
        let result: any = [];
        try {
            const response: any = await fetch(`/api-py/get-dict-doh-childs-by-parent/${id}/${this.filterRegion}/${this.filterYear}`);
            result = await response.json();
            return result;
        } catch (error) {
            makeToast(this, 'danger', `Ошибка get-dict-doh-childs-by-parent (${id})`, (error as Error).toString());
            return [];
        }
    }

    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private addClk() {
        const resArr = [];
        for (const elSpf of this.curSpf) {
            let ifExist = false
            for (const tabEl of this.dataTable) {
                if (tabEl.type === 5) {
                    if (tabEl.kat === elSpf.kat && tabEl.clss === elSpf.clss && tabEl.pcl === elSpf.pcl && tabEl.spf === elSpf.spf) {
                        ifExist = true
                    }
                }
            }
            if (!ifExist) {
                const pushItem = Object.assign({}, elSpf)
                pushItem.katName = this.curKat.name_ru
                pushItem.clssName = this.curCls.name_ru
                pushItem.pclName = this.curPcl.name_ru
                resArr.push(pushItem)
            } else {
                makeToast(this, 'warning', 'Добавление элемента', 'Элемент уже существует!' + `\n${elSpf.spf} - ${elSpf.name_ru}`);
            }
        }
        if (resArr.length) {
            this.$emit('addItemClk', resArr);
            this.showModal = false;
        }
    }

}
