





































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import CIpf from '@/modules/budget/execution/form/tabs/ipf/Ipf.vue';
import CPfAbp from '@/modules/budget/execution/form/tabs/pf-abp/PfAbp.vue';
import CBudgetExecutionIncome from '@/modules/budget/execution/form/tabs/income/BudgetExecutionIncome.vue';
import CBudgetExecutionBalancing from '@/modules/budget/execution/form/tabs/balancing/BudgetExecutionBalancing.vue';
import BudgetExecutionSpf from '@/modules/budget/execution/form/tabs/spf/BudgetExecutionSpf.vue';
import store from "@/services/store";
import {Ax} from "@/utils";
import VueElementLoading from "vue-element-loading";

@Component({
    components: {
        'c-ipf': CIpf,
        'c-pf-abp': CPfAbp,
        'c-budget-execution-income': CBudgetExecutionIncome,
        'c-budget-execution-balancing': CBudgetExecutionBalancing,
        'c-budget-execution-spf': BudgetExecutionSpf,
        'loading': VueElementLoading
    }
})
export default class CBudgetExecutionForm extends Vue {
    private access_module: string | null = null; // наименование доступа (УФ, АБП, ГУ)
    private userModules = store.state.user.userModules

    private getUserModuleAccess(code:string) {
        for (const avModule of this.userModules) {
            if (avModule.modules === code) {
                return true
            }
        }
        return false
    }
    private async mounted() {
        this.$watch('usrId', this.getAccess);
    }
    private async created() {
        await this.getAccess();
    }

    private loading = false;

    private get usrId() {
        if (store.state.user.sub === undefined) {
            return null;
        }
        return store.state.user.sub;
    }

    private async getAccess() {
        try {
            if (this.usrId) {
                const result = await fetch('/api-py/budget-execution-get-access-module/' + this.usrId);
                if (result.status === 200) {
                    this.access_module = await result.json();
                } else {
                    this.makeToast('danger', 'get-access', `${result.status} - ${result.statusText}`);
                }
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса get-access', error.toString());
        }
    }

    private async downloadApplication(app: number) {
        this.loading = true;
        let dat: any = {};
        let d: any = {};
        let app_name = ''
        const ipf: any = this.$refs.ipf
        if (app < 7) {
            dat = this.$refs.ipf
            const selectedIpf = ipf.$refs.guWindow.selectedIpf
            if (!selectedIpf) {
                this.makeToast('warning', 'Сообщение', 'Не выбрана форма');
                this.loading = false;
            } else {
                d = {
                    'year': selectedIpf.year,
                    'abp': selectedIpf.abp,
                    'gu': selectedIpf.gu,
                    'id': selectedIpf.id,
                    'region_name': dat.region.name_ru,
                    'region_name_kz': dat.region.name_kk,
                    'abp_name': dat.curAbp.name_ru,
                    'abp_name_kz': dat.curAbp.name_kk,
                    'region': selectedIpf.region,
                    'gr': dat.filter.curGr,
                    'prg': 0,
                    'ppr': 0,
                    'spf': 0,
                    'app': app,
                    'treeData': []
                }
                app_name = `Приложение ${app}_${d.gu}_${d.year}.xlsx`
            }
        } else if (app < 11) {
            dat = this.$refs.pf_abp
            const selectedPf = dat.$refs.guWindow.selectedPf
             if (!selectedPf) {
                this.makeToast('warning', 'Сообщение', 'Не выбрана форма');
                this.loading = false;
            } else {
                d = {
                    'id': selectedPf.id,
                    'year': selectedPf.year,
                    'abp': selectedPf.abp,
                    'abp_name': dat.curAbp.name_ru,
                    'abp_name_kz': dat.curAbp.name_kk,
                    'region': selectedPf.region,
                    'region_name': dat.curReg.name_ru,
                    'region_name_kz': dat.curReg.name_kk,
                    'prg': 0,
                    'ppr': 0,
                    'spf': 0,
                    'app': app,
                    'treeData': [],
                    'user_id': this.usrId,
                    'location': 0
                }
                 app_name = `Приложение ${app}_${d.abp}_${d.year}.xlsx`
            }
        } else if (app < 16) {
            dat = this.$refs.budget_execution_income
            let kat = null;
            if (dat.repKat && dat.repKat.hasOwnProperty('kat')) {
                kat = dat.repKat.kat
            }
            d = {
                'kat': kat,
                'cls': dat.repCls && dat.repCls.cls ? dat.repCls.cls: null,
                'pcl': dat.repSubCls && dat.repSubCls.pcl ? dat.repSubCls.pcl : null,
                'spf': dat.repSpec && dat.repSpec.spf ? dat.repSpec.spf: null,
                'year': dat.repYear,
                'region': dat.reg.code,
                'app': app,
                'treeData': []
            }
            app_name = `Приложение ${app}_${d.region}_${d.year}.xlsx`
        } else {
            dat = this.$refs.budget_execution_spf
            let abp = null;
            if (dat.curAbp && dat.curAbp.hasOwnProperty('abp')) {
                abp = dat.curAbp.abp
            }
            d = {
                'region': dat.curReg.code,
                'abp': abp,
                'prg': dat.curProg && dat.curProg.prg ? dat.curProg.prg : null,
                'ppr': dat.curSubProg && dat.curSubProg.ppr ? dat.curSubProg.ppr : null,
                'spf': dat.curSpf && dat.curSpf.name !== 'Все' ? dat.curSpf.spf : null,
                'year': dat.repYear,
                'region_name': dat.curReg.name_ru,
                'region_name_kz': dat.curReg.name_kk,
                'app': app,
                'treeData': []
            }
            app_name = `Приложение ${app}_${d.region}_${d.year}.xlsx`
        }
        if (d.id || app > 11) {
            Ax(
                {
                    url: '/api-py/get-budget-execution-report/',
                    method: 'POST',
                    responseType: 'blob',
                    data: d
                },
                (data: any) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', app_name);
                    document.body.appendChild(link);
                    link.click();
                    this.loading = false;

                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadApplication()', error.toString());
                    this.loading = false;
                }
            )
        }
    }

    private downloadRepPayments() {
        if (typeof this.$refs.ipf !== 'undefined') {
            const paymentsRep: any = this.$refs.ipf;
            paymentsRep.downloadRepPayments();
            // this.$refs.ipf.downloadRepPayments();
        }
    }
    private downloadRepIndPayments() {
        if (typeof this.$refs.ipf !== 'undefined') {
            const paymentsRep: any = this.$refs.ipf;
            paymentsRep.downloadRepIndPayments();
        }
    }
    private downloadRepIndPayments3() {
        if (typeof this.$refs.ipf !== 'undefined') {
            const paymentsRep: any = this.$refs.ipf;
            paymentsRep.downloadRepIndPayments3();
        }
    }
    private downloadRepObligations() {
        if (typeof this.$refs.ipf !== 'undefined') {
            const ObligationsRep: any = this.$refs.ipf;
            ObligationsRep.downloadRepObligations();
            // this.$refs.ipf.downloadRepObligations();
        }
    }
    private downloadRepIndObligations() {
        if (typeof this.$refs.ipf !== 'undefined') {
            const ObligationsRep: any = this.$refs.ipf;
            ObligationsRep.downloadRepIndObligations();
        }
    }
    private downloadRepIndObligations6() {
        if (typeof this.$refs.ipf !== 'undefined') {
            const ObligationsRep: any = this.$refs.ipf;
            ObligationsRep.downloadRepIndObligations6();
        }
    }
    private downloadRepIndPayments11() {
        if (typeof this.$refs.ipf !== 'undefined') {
            const ObligationsRep: any = this.$refs.ipf;
            ObligationsRep.downloadRepIndPayments11();
        }
    }
    private downloadRepIndObligations12() {
        if (typeof this.$refs.ipf !== 'undefined') {
            const ObligationsRep: any = this.$refs.ipf;
            ObligationsRep.downloadRepIndObligations12();
        }
    }
    private downloadRepAbpObligations() {
        if (typeof this.$refs.pf_abp !== 'undefined') {
            const ObligationsRep: any = this.$refs.pf_abp;
            ObligationsRep.downloadRepObligations();
        }
    }
    private downloadRepIndAbpObligations() {
        if (typeof this.$refs.pf_abp !== 'undefined') {
            const ObligationsRep: any = this.$refs.pf_abp;
            ObligationsRep.downloadRepIndObligations();
        }
    }
    private downloadRepIndAbpPayments20() {
        if (typeof this.$refs.pf_abp !== 'undefined') {
            const ObligationsRep: any = this.$refs.pf_abp;
            ObligationsRep.downloadRepIndAbpPayments20();
        }
    }
    private downloadRepIndAbpObligations21() {
        if (typeof this.$refs.pf_abp !== 'undefined') {
            const ObligationsRep: any = this.$refs.pf_abp;
            ObligationsRep.downloadRepIndAbpObligations21();
        }
    }
    private downloadRepAbpPayments() {
        if (typeof this.$refs.pf_abp !== 'undefined') {
            const paymentsRep: any = this.$refs.pf_abp;
            paymentsRep.downloadRepPayments();
        }
    }
    private downloadRepIndAbpPayments() {
        if (typeof this.$refs.pf_abp !== 'undefined') {
            const paymentsRep: any = this.$refs.pf_abp;
            paymentsRep.downloadRepIndPayments();
        }
    }
    private downloadRepConsolidatedIncomePlan14() {
        if (typeof this.$refs.budget_execution_income !== 'undefined') {
            const consolidated: any = this.$refs.budget_execution_income;
            consolidated.downloadRepConsolidatedIncomePlan14();
        }
    }
    private downloadRepConsolidatedIncomePlan15() {
        if (typeof this.$refs.budget_execution_income !== 'undefined') {
            const consolidated: any = this.$refs.budget_execution_income;
            consolidated.downloadRepConsolidatedIncomePlan15();
        }
    }
    private downloadRepBalancing() {
        if (typeof this.$refs.balancing !== 'undefined') {
            const balance: any = this.$refs.balancing;
            balance.downloadRepBalancing();
        }
    }
    private downloadRepSpfPayments() {
        if (typeof this.$refs.budget_execution_spf !== 'undefined') {
            const spf: any = this.$refs.budget_execution_spf;
            spf.downloadRepSpfPayments();
        }
    }
    private downloadRepSpfPayments17() {
        if (typeof this.$refs.budget_execution_spf !== 'undefined') {
            const spf: any = this.$refs.budget_execution_spf;
            spf.downloadRepSpfPayments17();
        }
    }
    private downloadRepSpfObligations() {
        if (typeof this.$refs.budget_execution_spf !== 'undefined') {
            const spf: any = this.$refs.budget_execution_spf;
            spf.downloadRepSpfObligations();
        }
    }
    private downloadRepSpfObligations19() {
        if (typeof this.$refs.budget_execution_spf !== 'undefined') {
            const spf: any = this.$refs.budget_execution_spf;
            spf.downloadRepSpfObligations19();
        }
    }

    private async downloadIISKReport(ref: any, app: any|number) {
        const component: any = this.$refs[ref];
        if (component) {
            component.downloadIISKReport(app);
        }
    }

    private infoModule(info_id:any, file:any) {
        const that = this;
        Ax(
            {
                url: '/api-py/get-info/' + info_id, //  Тело файла
                method: 'POST',
                data: null,
                responseType: 'blob'
            },
            (data:any) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file + '.pdf');// or any other extension
                document.body.appendChild(link);
                link.click();
            },
            (error:any) => {
                this.makeToast('danger', 'Ошибка загрузки', error.toString());
            }
        );
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
