

































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CSpfTable extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private showData!: boolean;

    @Prop({
        required: true,
        default: []
    })
    private tableData!: any;
    @Prop({
        required: true,
        default: null
    })
    private tableDataParents!: any;
    private tableFields = [
        {
            key: 'action',
            label: ''
        },
        {
            key: 'gr',
            label: 'Гр'
        },
        {
            key: 'pgr',
            label: 'Фпг'
        },
        {
            key: 'abp',
            label: 'Абп'
        },
        {
            key: 'prg',
            label: 'Программа'
        },
        {
            key: 'ppr',
            label: 'Подпрограмма'
        },
        {
            key: 'spf',
            label: 'Специфика'
        },
        {
            key: 'bip_code',
            label: 'Проект'
        },
        // {
        //     key: 'name_ru',
        //     label: 'Наименование'
        // },
        {
            key: 'deviation',
            label: 'Финансовый ' +
                'план на год'
        },
        {
            key: 'january',
            label: 'Январь'
        },
        {
            key: 'february',
            label: 'Февраль'
        },
        {
            key: 'march',
            label: 'Март'
        },
        {
            key: 'april',
            label: 'Апрель'
        },
        {
            key: 'may',
            label: 'Май'
        },
        {
            key: 'june',
            label: 'Июнь'
        },
        {
            key: 'july',
            label: 'Июль'
        },
        {
            key: 'august',
            label: 'Август'
        },
        {
            key: 'september',
            label: 'Сентябрь'
        },
        {
            key: 'october',
            label: 'Октябрь'
        },
        {
            key: 'november',
            label: 'Ноябрь'
        },
        {
            key: 'december',
            label: 'Декабрь'
        }
    ];

    private collapse = true;
    private calcFlds = [
        'plan',
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
    ];

    clkCollapseAll(data: any, collapse = true) {
        this.$set(data, 'collapse', collapse);
        if ('child_list' in data && data.child_list && data.child_list.length) {
            for (const el of data.child_list) { this.clkCollapseAll(el, collapse) }
        } else if ('form_child_list' in data && data.form_child_list && data.form_child_list.length) {
            for (const el of data.form_child_list) { this.clkCollapseAll(el, collapse) }
        } else if ('spf_list' in data && data.spf_list && data.spf_list.length) {
            for (const el of data.spf_list) { this.clkCollapseAll(el, collapse) }
        }
    }
    clkCollapse(gr: any = null, pgr: any = null, abp: any = null, prg: any = null, ppr: any = null, spf: any = null, collapse: boolean = false) {
        if (gr !== null) {
            let obj = this.tableData[gr]
            if (pgr !== null) {
                obj = obj.child_list[pgr]
            }
            if (abp !== null) {
                obj = obj.child_list[abp]
            }
            if (prg !== null) {
                obj = obj.child_list[prg]
            }
            if (ppr !== null) {
                obj = obj.child_list[ppr]
            }
            if (spf !== null) {
                obj = obj.child_list[spf]
            }
            if (collapse) {
                this.clkCollapseAll(obj, collapse)
            }
            this.$set(obj, 'collapse', collapse);
        }
        else {
            this.collapse = collapse;
            for (const el of this.tableData) {
                this.clkCollapseAll(el, collapse)
            }
        }
    }


    private sumDeviation(row: any) {
        let sum = 0;
        for (const mon of this.calcFlds.slice(1)) {
            sum += row[mon]
        }
        this.$set(row, 'deviation', sum)
        return sum
    };
    private total(field: string) {
        let sum = 0;
        for (const row of this.tableData) {
            sum += parseFloat(row[field]);
        }
        return sum;
    } // итого по заданному полю

}
