



































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class CIpfTableForObligation extends Vue {
    @Prop({
        required: true,
        default: null
    })
    private showData!: boolean;

    @Prop({
        required: true,
        default: false
    })
    isEmptyTotal!: boolean;

    @Prop({
        required: true,
        default: null
    })
    private tableData!: any;

    @Prop({
        required: true,
        default: null
    })
    private typeTable!: string;

    @Prop({
        required: true,
        default: false
    })
    private edit_access!: boolean;
    @Prop({
        required: true,
        default: false
    })
    private delete_access!: boolean;
    @Prop({
        required: true,
        default: null
    })
    private filterAttrs!: any;

    @Prop({
        required: true,
        default: null
    })
    private spfList!: any;

    private created() {
        this.$watch('filterAttrs', () => (this.open = false));
    }

    private tableFields = [
        // {
        //     key: 'action',
        //     label: ''
        // },
        {
            key: 'prg',
            label: 'ПРОГРАММА'
        },
        {
            key: 'ppr',
            label: 'ПОДПРОГРАММА'
        },
        {
            key: 'spf',
            label: 'СПЕЦИФИКА'
        },
        {
            key: 'bip_code',
            label: 'Проект'
        },
        {
            key: 'plan',
            label: `БЗ${this.filterAttrs.year}`
        },
        {
            key: 'deviation',
            label: 'Финансовый план на год',
            variant: 'secondary'
        },
        {
            key: 'january',
            label: 'Январь'
        },
        {
            key: 'february',
            label: 'Февраль'
        },
        {
            key: 'march',
            label: 'Март'
        },
        {
            key: 'april',
            label: 'Апрель'
        },
        {
            key: 'may',
            label: 'Май'
        },
        {
            key: 'june',
            label: 'Июнь'
        },
        {
            key: 'july',
            label: 'Июль'
        },
        {
            key: 'august',
            label: 'Август'
        },
        {
            key: 'september',
            label: 'Сентябрь'
        },
        {
            key: 'october',
            label: 'Октябрь'
        },
        {
            key: 'november',
            label: 'Ноябрь'
        },
        {
            key: 'december',
            label: 'Декабрь'
        }
    ];

    private checkMonth = [
        {
            key: 'january',
            check: 'january'
        },
        {
            key: 'february',
            check: 'checkFeb'
        },
        {
            key: 'march',
            check: 'checkMar'
        },
        {
            key: 'april',
            check: 'checkApr'
        },
        {
            key: 'may',
            check: 'checkMay'
        },
        {
            key: 'june',
            check: 'checkJun'
        },
        {
            key: 'july',
            check: 'checkJul'
        },
        {
            key: 'august',
            check: 'checkAug'
        },
        {
            key: 'september',
            check: 'checkSep'
        },
        {
            key: 'october',
            check: 'checkOct'
        },
        {
            key: 'november',
            check: 'checkNov'
        },
        {
            key: 'december',
            check: 'checkDec'
        }
    ]

    private open = false;

    private filter: any =  {
        prg: null,
        ppr: null,
        spf: null,
        bip_code: null,
        name_ru: null
    };

    private filtered = false;

    private showPopupIndex: number|null = null;

    private selectedSpfRow: any | null = null;

    private openModal(item: any) {
        this.selectedSpfRow = item;

        this.$bvModal.msgBoxConfirm(
            'Вы хотите выполнить разноску годовой суммы бюджета по месяцам?',
            {
                title: 'Подтверждение',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'ДА',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    const monthlyShare = this.selectedSpfRow.plan / 12;
                    let newSum = 0;
                    for (const mon of this.checkMonth) {
                        this.selectedSpfRow[mon.key] = parseFloat((monthlyShare).toFixed(1));
                        newSum += this.selectedSpfRow[mon.key];
                    }
                    const dif = parseFloat((this.selectedSpfRow.plan - newSum).toFixed(1));
                    if (dif !== 0) {
                        this.selectedSpfRow.december += dif;
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    private openAll(forceOpen: string) {
        if (forceOpen) {
            if (forceOpen === 'open') {
                this.open = true;
            } else {
                this.open = false;
            }
        } else {
            this.open = !this.open;
        }
        for (const row of this.tableData) {
            if (row.hasOwnProperty('type') && row.type === 4) {
                row.open = this.open;
            } else {
                row.visible = this.open;
                row.open = this.open;
            }
        }
    } // открывает.закрывает все ветки

    private openChilds(parent: any) {
        parent.open = !parent.open;

        for (const row of this.tableData) {
            if (parent.id === row.parent_id) {
                row.visible = parent.open;
                if (!parent.open) {
                    if (row.hasChild && row.open !== parent.open) {
                        this.openChilds(row);
                    }
                    if (row.hasFormChild && row.open !== parent.open) {
                        this.openChilds(row);
                    }
                }
            }
        }
    } // открывает/закрывает ветку до конечного элемента

    private inputFixed(item: any, field: any, value: any, digit: any) {
        item.hasChanges = true
        if (item.type === 7 && item.parent.zeroBip) {
            item.parent.zeroBip = false
            this.$emit('calcFields', item.parent, item.parent.typeTable, true, false)
        }
        if (value) {
            this.$set(item, field, parseFloat(parseFloat(value).toFixed(digit)));
        } else {
            this.$set(item, field, 0);
        }
    }

    private deleteSpec(data: any) {
        const body = { data: data, type: 'obligation' }
        this.$bvModal.msgBoxConfirm(
            'Вы действительно хотите удалить данную запись?',
            {
                title: 'Подтверждение',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'ДА',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.$emit('deleteSpec', body)
                }
            })
            .catch(error => {
                this.makeToast('danger', 'Ошибка сохранения', error.toString());
            });
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private keyPress(event: any, pattern: any) {
        // const regex = new RegExp('^[0-9]+$');
        // const regex = new RegExp('^-?\\d*\\d{0,9}$');
        // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
        const regex = new RegExp(pattern);
        const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    } // вводит по заданному паттерну

    private keyup13(event: any) {
        event.preventDefault();
        // Isolate the node that we're after
        const currentNode = event.target;
        // find all tab-able elements
        const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
        // Find the current tab index.
        const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
        if (!event.target.value) {
            event.target.value = 0;
        }
        // select/focus the following element
        const targetIndex = (currentIndex + 1) % allElements.length;
        if (targetIndex < allElements.length) {
            allElements[targetIndex].select();
        }
    }// enter работает как tab

    private total(field: string) {
        let sum = 0;
        for (const row of this.tableData) {
            if (row.type === 4) {
                sum += parseFloat(row[field]);
            }
        }
        return parseFloat((Math.round(sum * 100) / 100).toFixed(1));
    } // итого по заданному полю

    private showPopup(index: number) {
        this.showPopupIndex = index;
    }
    private hidePopup() {
        this.showPopupIndex = null;
    }
}
