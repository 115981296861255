


























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Ax} from "@/utils";
import BExecAgrHistModal from '@/modules/budget/execution/form/tabs/components/BExecAgrHistModal.vue'

@Component({
    components: {
        'b-exec-agr-hist-modal': BExecAgrHistModal
    }
})
export default class CGuWindow extends Vue {
    $refs!: {
        refHistModal: HTMLFormElement
    }
    @Prop({
        required: true,
        default: null
    })
    private filter!: any;


    @Prop({
        required: true,
        default: false
    })

    @Prop({
        required: true,
        default: null
    })
    private accessLevel!: string;

    @Prop({
        required: true,
        default: null
    })
    private edit_access!: string;

    @Prop({
        required: true,
        default: null
    })
    private delete_access!: string;

    private progress: number | null = null;


    @Prop({
        required: true,
        default: []
    })
    private ipfData!: any[];

    @Prop({
        required: true,
        default: []
    })
    private statusTypes!: any[]

    private async created() {}

    onChange(item:any){
        // this.$emit('onChange', item);
    }
    onDelete(item:any) {
        this.$bvModal.msgBoxConfirm(
            'Вы действительно хотите удалить данный ИПФ?',
            {
                title: 'Подтверждение',
                size: 'lg',
                buttonSize: 'sm',
                okVariant: 'success',
                okTitle: 'ДА',
                cancelTitle: 'Отмена',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(async value => {
                if (value) {
                    this.$emit('onDelete', item);
                }
            })
            .catch(error => {});

    }

    //exipf


    private toggleOpenWindowBool(row:any = null) {
        this.$emit('toggleOpenWindowBool', row);
    }

    private formatDate(value: string): string | null {
        if (value) {
            const date = new Date(value);
            return date.toLocaleDateString();
        }
        return null;
    }

    private getFullName(row:any) {
        if (row.gu) {
            return `Индивидуальный план финансирования на ${row.year} по ${row.gu} - ${row.gu_name}`
        }
        return `Индивидуальный план финансирования на ${row.year}`
    }

    getLocalStatus(value:any) {
        const status = value.status.status;
        const curStatus = this.statusTypes.filter((item) => item.code === status);
        if (!curStatus || !curStatus.length) return status;
        const localName = curStatus[0]["name_" + this.$i18n.locale];
        return localName;
    }

    private makeToast(variant: any, title: any, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private async mounted() {
        this.$watch('ipfData', () => { this.selectedIpf = null; })
    }

    private selectedIpf = null;

    private downloadApp(item: any) {
        for (const r of this.ipfData) {
            r.selected = false;
        }
        item.selected = !item.selected;
        this.selectedIpf = item;
        item.id += 1;
        item.id -= 1;
    }

    private async openHistory(agrObj: any) {
        if (agrObj !== null) {
            await this.$refs.refHistModal.showEvent(agrObj);
        }
    }
}
